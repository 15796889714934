<template>
  <div>
    <!--begin::User-->
    <!--    <div class="card card-custom">-->
    <div>
      <div v-if="PlanMessage? true: false" class="card-header d-block border-0 pt-6 pb-0">
        <div>
          <b-alert show variant="danger">{{ PlanMessage }}</b-alert>
        </div>
      </div>
      <!--      <div class="card-header flex-wrap border-0 pt-6 pb-0">-->
      <!--        <div class="card-title m-0">-->
      <h3 class="card-label">
        {{ $t('items.item') }}
        <span class="text-muted pt-2 font-size-sm d-block"></span>
      </h3>
      <!--        </div>-->
      <!--      </div>-->
      <b-tabs class="nav-custom-link" content-class="mt-3">
        <b-tab :title="$t('items.basic_information')" active @click="toggleTab('basic_information')">

          <div class="row">
            <div class="col-lg-6">
              <div class="card card-custom">
                <div class="card-body row">
                  <div class="col-lg-12 pt-5">
                    <div class="form-group">
                      <custom-upload-image-2 :image-url="image_url" :start-link="'base'" :upload="dir_upload"
                                             @file="listenerAttachment"></custom-upload-image-2>
                    </div>
                  </div>
                  <div class="col-lg-12 mb-5">
                    <label>{{ $t('items.name') }}<span class="text-danger">*</span></label>
                    <input v-model="data.name" :class="validation && validation.name ? 'is-invalid' : ''"
                           :placeholder="$t('items.name')"
                           class="form-control" type="text"/>
                    <span v-if="validation && validation.name" class="fv-plugins-message-container invalid-feedback">
                      {{ validation.name[0] }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="card card-custom mt-5">
                <div class="card-body row">
                  <!--                  <div class="col-lg-6 mb-5">-->
                  <!--                    <label>{{ $t('items.initial_stock_level') }}</label>-->
                  <!--                    <input type="text" v-model="data.initial_stock_level" class="form-control"-->
                  <!--                           :class="validation && validation.initial_stock_level ? 'is-invalid' : ''"-->
                  <!--                           :placeholder="$t('items.initial_stock_level')"/>-->
                  <!--                    <span v-if="validation && validation.initial_stock_level"-->
                  <!--                          class="fv-plugins-message-container invalid-feedback">-->
                  <!--                                {{ validation.initial_stock_level[0] }}-->
                  <!--                            </span>-->
                  <!--                  </div>-->
                  <!--                  <div class="col-lg-6 mb-5">-->
                  <!--                    <label>{{ $t('items.low_stock_threshold') }}</label>-->
                  <!--                    <input type="text" v-model="data.low_stock_threshold" class="form-control"-->
                  <!--                           :class="validation && validation.low_stock_threshold ? 'is-invalid' : ''"-->
                  <!--                           :placeholder="$t('items.low_stock_threshold')"/>-->
                  <!--                    <span v-if="validation && validation.low_stock_threshold"-->
                  <!--                          class="fv-plugins-message-container invalid-feedback">-->
                  <!--                                {{ validation.low_stock_threshold[0] }}-->
                  <!--                            </span>-->
                  <!--                  </div>-->

                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('items.re_order') }}</label>
                    <input v-model="data.re_order" :class="validation && validation.re_order ? 'is-invalid' : ''"
                           :placeholder="$t('items.re_order')"
                           class="form-control"
                           type="number"/>
                    <span v-if="validation && validation.re_order"
                          class="fv-plugins-message-container invalid-feedback">
                      {{ validation.re_order[0] }}
                    </span>
                  </div>
                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('items.sum_available_qty') }}</label>
                    <input v-model="data.sum_available_qty" :placeholder="$t('items.sum_available_qty')"
                           class="form-control" disabled
                           type="text"/>
                  </div>
                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('items.sum_stock_on_hand_qty') }}</label>
                    <input v-model="data.sum_stock_on_hand_qty" :placeholder="$t('items.sum_stock_on_hand_qty')"
                           class="form-control" disabled
                           type="number"/>
                  </div>
                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('items.minimum_qty') }}</label>
                    <input v-model="data.minimum_qty" :class="validation && validation.minimum_qty ? 'is-invalid' : ''"
                           :placeholder="$t('items.minimum_qty')" class="form-control"
                           min="0"
                           type="number"/>
                    <span v-if="validation && validation.minimum_qty"
                          class="fv-plugins-message-container invalid-feedback">
                      {{ validation.minimum_qty[0] }}
                    </span>
                  </div>
                  <!--                  <div class="col-lg-6 mb-5">-->
                  <!--                    <label>&ensp;</label>-->
                  <!--                    <b-form-checkbox size="lg" v-model="data.is_tracking" name="check-button" switch>-->
                  <!--                      {{ $t('items.is_tracking') }}-->
                  <!--                    </b-form-checkbox>-->
                  <!--                  </div>-->

                  <div class="col-lg-6 mb-5">
                      <label>&ensp;</label>
                      <b-form-checkbox v-model="data.is_committed" :disabled="is_committed_sales_disabled" name="check-button" size="lg" switch>
                        {{ $t('items.is_committed') }}
                      </b-form-checkbox>
                  </div>

                  <div class="col-lg-6 mb-5">
                    <label>&ensp;</label>
                    <b-form-checkbox v-model="data.is_manufacture" name="check-button" size="lg" switch>
                      {{ $t('items.is_manufacturing_item') }}
                    </b-form-checkbox>
                  </div>
                </div>
              </div>

            </div>

            <div class="col-lg-6">
              <div class="card card-custom">
                <div class="card-body row">
                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('items.sku_code') }}</label>
                    <input v-model="data.sku_code" :class="validation && validation.sku_code ? 'is-invalid' : ''"
                           :placeholder="$t('items.sku_code')"
                           class="form-control"
                           type="text"/>
                    <span v-if="validation && validation.sku_code"
                          class="fv-plugins-message-container invalid-feedback">
                      {{ validation.sku_code[0] }}
                    </span>
                  </div>

                  <!-- <div class="col-lg-6 mb-5">
                    <label>{{ $t('items.barcode') }}</label>
                    <input type="text" v-model="data.barcode" class="form-control"
                           :class="validation && validation.barcode ? 'is-invalid' : ''"
                           :placeholder="$t('items.barcode')"/>
                    <span v-if="validation && validation.barcode" class="fv-plugins-message-container invalid-feedback">
                        {{ validation.barcode[0] }}
                    </span>
                  </div> -->
                  <div class="col-md-6 mb-5">
                    <label>{{ $t('items.category') }}<span class="text-danger">*</span></label>
                    <div class="input-group mb-3">
                      <treeselect
                          :class="validation && validation.category_id ? 'is-invalid' : ''"
                          :load-options="loadOptions"
                          :multiple="false"
                          :no-children-text="$t('No_sub_options')"
                          :no-options-text="$t('No_options_available')"
                          :no-results-text="$t('No_results_found')"
                          :options="categories"
                          :placeholder="$t('Select')"
                          :searchable="true"
                          :show-count="true"
                          :value="data.category_id"
                          @select="updateValue">
                      </treeselect>
                      <div class="input-group-prepend">
                        <a class="btn btn-primary" href="/settings/categories" target="_blank"><i class="fa fa-plus"
                                                                                                  style="padding: 0"></i></a>
                      </div>
                    </div>
                    <span v-if="validation && validation.category_id"
                          class="fv-plugins-message-container invalid-feedback">
                      {{ validation.category_id[0] }}
                    </span>
                  </div>
                  <div class="col-md-6 mb-5">
                    <label>{{ $t('items.brand') }}</label>
                    <div class="input-group mb-3">
                      <multiselect v-model="brand" :multiple="false" :options="brands" :placeholder="$t('items.brand')"
                                   :show-labels="false" :show-no-options="false" :show-no-results="false"
                                   :taggable="false"
                                   label="name"
                                   track-by="id">
                      </multiselect>
                      <div class="input-group-prepend">
                        <a class="btn btn-primary" href="/settings/brands" target="_blank"><i class="fa fa-plus"
                                                                                              style="padding: 0"></i></a>
                      </div>
                    </div>
                    <span v-if="validation && validation.brand_id"
                          class="fv-plugins-message-container invalid-feedback">
                      {{ validation.brand_id[0] }}
                    </span>
                  </div>

                  <div class="col-lg-6 mb-5">
                    <div>
                      <label>{{ $t('items.related_items') }}</label>
                      <!-- <multiselect
                          v-model="related_item_data"
                          :placeholder="$t('items.related_items')"
                          label="name"
                          track-by="id"
                          :options="related_items"
                          @input="getRelatedItemById"
                          :multiple="true"
                          :taggable="false"
                          :show-labels="false"
                          :show-no-options="false"
                          :show-no-results="false">
                      </multiselect> -->

                      <multiselect v-model="related_item_data"
                                   :internal-search="false"
                                   :label="f_by?f_by:'name'"
                                   :multiple="true"
                                   :options="related_items"
                                   :placeholder="$t('items.related_items')"
                                   :show-labels="false"
                                   :show-no-options="false"
                                   :show-no-results="false"
                                   :taggable="false"
                                   track-by="id"
                                   @input="getRelatedItemById"
                                   @search-change="getItems(f_by, $event)">
                      </multiselect>
                    </div>
                  </div>

                  <div class="col-md-6 mb-5">
                    <label>{{ $t('items.unit') }}<span class="text-danger">*</span></label>
                    <div class="input-group mb-3">
                      <multiselect v-model="unit"
                                   :multiple="false"
                                   :options="units"
                                   :placeholder="$t('items.unit')"
                                   :show-labels="false"
                                   :show-no-options="false"
                                   :show-no-results="false"
                                   :taggable="false"
                                   label="name"
                                   track-by="id">
                      </multiselect>
                      <div class="input-group-prepend">
                        <a class="btn btn-primary" href="/settings/units" target="_blank"><i class="fa fa-plus"
                                                                                             style="padding: 0"></i></a>
                      </div>
                    </div>
                    <span class="form-text text-muted" style="text-align:end">
                      {{ $t('type_at_least_three_letters') + ' ' + $t('items.unit') }}.
                    </span>
                    <span v-if="validation && validation.unit_id" class="fv-plugins-message-container invalid-feedback">
                      {{ validation.unit_id[0] }}
                    </span>
                  </div>
<!--                  <div class="col-lg-6 mb-5">-->
<!--                    <label>&ensp;</label>-->
<!--                    <b-form-checkbox v-model="data.is_min" name="check-button" size="lg" switch>{{ $t('minimum_unit') }}-->
<!--                    </b-form-checkbox>-->
<!--                  </div>-->
                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('items.return_days') }}</label>
                    <input v-model="data.allowable_return_days"
                           @keydown="positiveNumber"
                           :class="validation && validation.allowable_return_days ? 'is-invalid' : ''"
                           :placeholder="$t('items.return_days')"
                           class="form-control"
                           type="number"/>
                    <span v-if="validation && validation.allowable_return_days"
                          class="fv-plugins-message-container invalid-feedback">
                      {{ validation.allowable_return_days[0] }}
                    </span>
                  </div>
                  <div class="col-md-6 mb-5">
                    <label>{{ $t('items.tags') }}</label>
                    <multiselect v-model="data.tags"
                                 :multiple="true"
                                 :options="tags_list" :placeholder="$t('items.tags')"
                                 :show-labels="false"
                                 :show-no-options="false"
                                 :show-no-results="false"
                                 :taggable="true"
                                 label="name"
                                 track-by="id"
                                 @tag="addTag">
                    </multiselect>
                    <span v-if="validation && validation.tags" class="fv-plugins-message-container invalid-feedback">
                      {{ validation.tags[0] }}
                    </span>
                  </div>
                  <div v-if="categoriSelectedType === 1" class="col-lg-6 mb-5">
                    <label for="scale_type">{{ $t('scale_type') }}</label>
                    <div class="input-group">
                      <select id="scale_type" v-model="data.scale_type" class="custom-select" name="">
                        <option v-for="row in scale_type_list" :key="row.id" :value="row.id">
                          {{ row.title }}
                        </option>
                      </select>
                      <span v-if="validation && validation.scale_type"
                            class="fv-plugins-message-container invalid-feedback">
                        {{ validation.scale_type[0] }}
                      </span>
                    </div>
                  </div>
                  <div class="col-lg-6 mb-5">
                    <label>&ensp;</label>
                    <b-form-checkbox v-model="data.is_active" name="check-button" size="lg" switch>{{ $t('status') }}
                    </b-form-checkbox>
                  </div>

                </div>
              </div>
              <div class="card card-custom mt-5">
                <div class="card-body row">
                  <div class="col-lg-6">
                    <label>{{ $t('items.special_description') }}</label>
                    <textarea v-model="data.special_description"
                              :class="validation && validation.special_description ? 'is-invalid' : ''"
                              :placeholder="$t('items.special_description')" class="form-control"
                              style="height: 86px;"
                              type="text"></textarea>
                    <span v-if="validation && validation.special_description"
                          class="fv-plugins-message-container invalid-feedback">
                      {{ validation.special_description[0] }}
                    </span>
                  </div>
                  <div class="col-lg-6">
                    <label>{{ $t('items.description') }}</label>
                    <textarea v-model="data.description"
                              :class="validation && validation.description ? 'is-invalid' : ''"
                              :placeholder="$t('items.description')" class="form-control"
                              style="height: 86px;"
                              type="text"></textarea>
                    <span v-if="validation && validation.description"
                          class="fv-plugins-message-container invalid-feedback">
                      {{ validation.description[0] }}
                    </span>
                  </div>
                </div>
              </div>

              <div class="card card-custom mt-5">
                <div class="card-body row">
                  <div class="col-lg-6">
                    <label>{{ $t('items.barcode') }}</label>
                    <div class="input-group mb-3">
                      <input v-model="data.barcode" :class="validation && validation.barcode ? 'is-invalid' : ''"
                             :placeholder="$t('items.barcode')"
                             class="form-control"
                             type="text"/>

                      <div class="input-group-prepend">
                        <button :title="$t('items.generate_barcode')" class="btn btn-primary" type="button"
                                @click="getGenerateBarcode"><i class="fa fa-barcode" style="padding: 0"></i>
                        </button>
                      </div>
                      <span v-if="validation && validation.barcode"
                            class="fv-plugins-message-container invalid-feedback">
                        {{ validation.barcode[0] }}
                      </span>
                    </div>
                  </div>
                  <div class="col-lg-6 mt-8">
                    <b-button class="btn btn-info mr-2" size="sm" @click="downloadBarcodeImage">
                      {{ $t('items.save_barcode') }}
                    </b-button>
                  </div>
                  <vue-barcode v-if="data.barcode" id="vueBarcode" :options="{ displayValue: true }"
                               :value="data.barcode"
                               tag="img"></vue-barcode>
                </div>
              </div>
            </div>


          </div>
          <!-- ******************* -->
          <div class="row mt-5">
            <div class="col-md-12">

              <div class="bg-FFB803 repeater p-3 d-flex justify-content-between">
                <h6 class="mt-2">{{ $t('items.other_barcodes') }}</h6>
                <button class="btn btn-primary mr-2" type="button" @click="addBarcodeToRepeater">{{
                    $t('add_more')
                  }}
                </button>
              </div>
              <div class="bg-white">
                <table class="table" @keyup.alt.enter="addBarcodeToRepeater" @keyup.alt.46="deleteBarcodeFromRepeater(0)">
                  <thead>
                  <tr class="text-center">
                    <th width="40%">{{ $t('items.barcode_number') }}</th>
                    <th width="40%">{{ $t('items.barcode') }}</th>
                    <th class="text-center" width="20%">

                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(itBarcode, index) in data_barcodes" :key="index">
                    <td>
                      <input v-model="itBarcode.item_barcodes" :placeholder="$t('items.barcode_number')"
                             class="form-control"
                             type="text"/>
                    </td>
                    <td class="text-center">
                      <!-- <input type="number" step="1.0" v-model="itBarcode.value_rate" class="form-control"/> -->
                      <vue-barcode v-if="itBarcode.item_barcodes" id="vueBarcode" :options="{ displayValue: true }"
                                   :value="itBarcode.item_barcodes" tag="img"></vue-barcode>
                    </td>

                    <td class="text-center">
                      <v-icon class="text-danger"
                              @click="itBarcode.id ? deleteBarcodeItem(itBarcode) : deleteBarcodeFromRepeater(index)">
                        mdi-delete
                      </v-icon>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

<!--          <div class="row mt-5">-->
<!--            <div class="col-md-12">-->

<!--              <div class="bg-FFB803 repeater p-3 d-flex justify-content-between">-->
<!--                <h6 class="mt-2">{{ $t('items.units') }}</h6>-->
<!--                <button class="btn btn-primary mr-2" type="button" @click="addUnitToRepeater">{{$t('add_more') }}</button>-->
<!--              </div>-->
<!--              <div class="bg-white">-->
<!--                <table class="table" @keyup.alt.enter="addUnitToRepeater" @keyup.alt.46="deleteUnitFromRepeater(0)">-->
<!--                  <thead>-->
<!--                  <tr class="text-center">-->
<!--                    <th width="40%">{{ $t('items.unit') }}</th>-->
<!--                    <th width="40%">{{ $t('items.number') }}</th>-->
<!--                    <th width="40%">{{ $t('status') }}</th>-->
<!--                    <th class="text-center" width="20%"></th>-->
<!--                  </tr>-->
<!--                  </thead>-->
<!--                  <tbody>-->
<!--                  <tr v-for="(row, index) in data_units" :key="index"  :class="getClassValidationRepeaterByIndex(index)">-->
<!--                    <td>-->
<!--                      <multiselect v-model="row.unit"-->
<!--                                   :multiple="false"-->
<!--                                   :options="units"-->
<!--                                   :placeholder="$t('items.unit')"-->
<!--                                   :show-labels="false"-->
<!--                                   :show-no-options="false"-->
<!--                                   :show-no-results="false"-->
<!--                                   :taggable="false"-->
<!--                                   @input="onChangeUnitsRepeater(index)"-->
<!--                                   label="name"-->
<!--                                   track-by="id">-->
<!--                      </multiselect>-->
<!--                      <span v-if="validation && validation[`units_list.${index}.item`]" class="fv-plugins-message-container invalid-feedback">-->
<!--                          {{ validation[`units_list.${index}.unit`][0] }}-->
<!--                        </span>-->
<!--                    </td>-->
<!--                    <td>-->
<!--                      <input v-model="row.number" @input="onChangeUnitsRepeater(index)" :placeholder="$t('items.number')" class="form-control" type="text"/>-->
<!--                      <span v-if="validation && validation[`units_list.${index}.number`]" class="fv-plugins-message-container invalid-feedback">-->
<!--                          {{ validation[`units_list.${index}.number`][0] }}-->
<!--                        </span>-->
<!--                    </td>-->
<!--                    <td>-->
<!--                      <b-form-checkbox v-model="row.is_active" name="check-button" size="lg" switch class="text-center"></b-form-checkbox>-->
<!--                    </td>-->
<!--                    <td class="text-center">-->
<!--                      <v-icon class="text-danger" @click="deleteUnitFromRepeater(index)">mdi-delete</v-icon>-->
<!--                    </td>-->
<!--                  </tr>-->
<!--                  </tbody>-->
<!--                </table>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->

        </b-tab>
        <b-tab :disabled="idEdit == null" :title="$t('items.financial_information')"
               @click="toggleTab('financial_information')">
          <div class="row">
            <div class="col-lg-6">
              <div class="card card-custom">
                <div class="card-body row">
                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('items.purchase_price') }}</label>
                    <input v-model="data.purchase_price"
                           :class="validation && validation.purchase_price ? 'is-invalid' : ''"
                           :placeholder="$t('items.purchase_price')"
                           class="form-control"
                           type="number"/>
                    <span v-if="validation && validation.purchase_price"
                          class="fv-plugins-message-container invalid-feedback">
                      {{ validation.purchase_price[0] }}
                    </span>
                  </div>
                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('items.sale_price') }}</label>
                    <input v-model="data.sale_price" :class="validation && validation.sale_price ? 'is-invalid' : ''"
                           :placeholder="$t('items.sale_price')"
                           class="form-control"
                           type="number"/>
                    <span v-if="validation && validation.sale_price"
                          class="fv-plugins-message-container invalid-feedback">
                      {{ validation.sale_price[0] }}
                    </span>
                  </div>
                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('items.mrp') }}</label>
                    <input v-model="data.mrp" :class="validation && validation.mrp ? 'is-invalid' : ''"
                           :placeholder="$t('items.mrp')"
                           class="form-control"
                           type="number"/>
                    <span v-if="validation && validation.mrp"
                          class="fv-plugins-message-container invalid-feedback">
                      {{ validation.mrp[0] }}
                    </span>
                  </div>
                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('items.min_sale_price') }}</label>
                    <input v-model="data.min_sale_price"
                           :class="validation && validation.min_sale_price ? 'is-invalid' : ''"
                           :placeholder="$t('items.min_sale_price')"
                           class="form-control"
                           type="number"/>
                    <span v-if="validation && validation.min_sale_price"
                          class="fv-plugins-message-container invalid-feedback">
                      {{ validation.min_sale_price[0] }}
                    </span>
                  </div>
                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('items.profit_margin') }}</label>
                    <input v-model="data.profit_margin"
                           :class="validation && validation.profit_margin ? 'is-invalid' : ''"
                           :placeholder="$t('items.profit_margin')"
                           class="form-control"
                           type="number"/>
                    <span v-if="validation && validation.profit_margin"
                          class="fv-plugins-message-container invalid-feedback">
                      {{ validation.profit_margin[0] }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="card card-custom">
                <div class="card-body row">
                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('items.discount_type') }}</label>
                    <div class="input-group">
                      <select id="discount_type" v-model="data.discount_type" class="custom-select" name="">
                        <option v-for="row in discount_type_list" :key="row.id" :value="row.id">
                          {{ row.name }}
                        </option>
                      </select>
                      <span v-if="validation && validation.discount_type"
                            class="fv-plugins-message-container invalid-feedback">
                        {{ validation.discount_type[0] }}
                      </span>
                    </div>
                  </div>
                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('items.discount') }}</label>
                    <input v-model="data.discount" :class="validation && validation.discount ? 'is-invalid' : ''"
                           :placeholder="$t('items.discount')"
                           class="form-control"
                           type="number"/>
                    <span v-if="validation && validation.discount"
                          class="fv-plugins-message-container invalid-feedback">
                      {{ validation.discount[0] }}
                    </span>
                  </div>
                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('items.wholesale_price') }}</label>
                    <input v-model="data.wholesale_price"
                           :class="validation && validation.wholesale_price ? 'is-invalid' : ''"
                           :placeholder="$t('items.wholesale_price')"
                           class="form-control"
                           type="number"/>
                    <span v-if="validation && validation.wholesale_price"
                          class="fv-plugins-message-container invalid-feedback">
                      {{ validation.wholesale_price[0] }}
                    </span>
                  </div>
                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('items.half_wholesale_price') }}</label>
                    <input v-model="data.half_wholesale_price"
                           :class="validation && validation.half_wholesale_price ? 'is-invalid' : ''"
                           :placeholder="$t('items.half_wholesale_price')"
                           class="form-control"
                           type="number"/>
                    <span v-if="validation && validation.half_wholesale_price"
                          class="fv-plugins-message-container invalid-feedback">
                      {{ validation.half_wholesale_price[0] }}
                    </span>
                  </div>
                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('items.branch_price') }}</label>
                    <input v-model="data.branch_price"
                           :class="validation && validation.branch_price ? 'is-invalid' : ''"
                           :placeholder="$t('items.branch_price')"
                           class="form-control"
                           type="number"/>
                    <span v-if="validation && validation.branch_price"
                          class="fv-plugins-message-container invalid-feedback">
                      {{ validation.branch_price[0] }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-12 pt-5">
              <div class="bg-FFB803 repeater p-3 d-flex justify-content-between">
                <h6 class="mt-2">{{ $t('items.taxes') }}</h6>
                <button class="btn btn-primary" type="button" @click="addToRepeater">{{ $t('add_more') }}</button>
              </div>
              <div class="bg-white">
                <table class="table table-custom-padding" @keyup.alt.enter="addToRepeater"
                       @keyup.alt.46="deleteFromRepeater(0)">
                  <thead>
                  <tr>
                    <th width="50%">
                      <div class="d-flex justify-content-between">
                        <span>{{ $t('items.tax') }}</span>
                        <a class="btn btn-primary btn-sm p-1" href="/settings/taxes/create" target="_blank">
                          <i class="fa fa-plus add-new"></i>
                        </a>
                      </div>
                    </th>
                    <th width="30%">{{ $t('items.percentage') }}</th>
                    <th width="30%">{{ $t('minimum') }}</th>
                    <th width="30%">{{ $t('items.apply_priority_tax') }}</th>
                    <th class="text-center" width="20%">{{ $t('actions') }}</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(tx, index) in data_taxes" :key="index">
                    <td>
                      <multiselect v-model="tx.tax"
                                   :multiple="false"
                                   :options="taxes"
                                   :placeholder="$t('items.tax')"
                                   :show-labels="false"
                                   :show-no-options="false"
                                   :show-no-results="false"
                                   :taggable="false"
                                   label="name"
                                   track-by="id"
                                   @input="handlingPercentageValue(index)">
                      </multiselect>
                    </td>
                    <td>
                      <input v-model="tx.percentage" :placeholder="$t('items.percentage')" class="form-control"
                             type="text"/>

                    </td>
                    <td>
                      <input v-model="tx.minimum" :placeholder="$t('minimum')" class="form-control" type="number"/>

                    </td>
                    <td>
                      <select id="apply_priority_tax" v-model="tx.priority" class="custom-select" name=""
                              @input="onPriorityTaxChanged(index)">
                        <option v-for="row in apply_priority_list" :key="row.id" :value="row.id">{{ row.name }}</option>
                      </select>
                    </td>
                    <td class="text-center">
                      <v-icon class="text-danger" @click="deleteFromRepeater(index)">mdi-delete</v-icon>

                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>


          <!--                                <div class="col-lg-6 mb-5">-->
          <!--                                    <label>{{$t('items.raw_material_price')}}</label>-->
          <!--                                    <input type="number" v-model="data.raw_material_price" class="form-control" :class="validation && validation.raw_material_price ? 'is-invalid' : ''" :placeholder="$t('items.purchase_price')"/>-->
          <!--                                    <span v-if="validation && validation.raw_material_price" class="fv-plugins-message-container invalid-feedback">-->
          <!--                                            {{ validation.raw_material_price[0] }}-->
          <!--                                    </span>-->
          <!--                                </div>-->
          <!--                                <div class="col-lg-6 mb-5">-->
          <!--                                    <label>{{$t('items.purchase_price_last_3_invoice')}}</label>-->
          <!--                                    <input type="number" v-model="data.purchase_price_last_3_invoice" class="form-control" :class="validation && validation.purchase_price_last_3_invoice ? 'is-invalid' : ''" :placeholder="$t('items.purchase_price_last_3_invoice')"/>-->
          <!--                                    <span v-if="validation && validation.purchase_price_last_3_invoice" class="fv-plugins-message-container invalid-feedback">-->
          <!--                                            {{ validation.purchase_price_last_3_invoice[0] }}-->
          <!--                                    </span>-->
          <!--                                </div>-->
          <!--                                <div class="col-lg-6 mb-5">-->
          <!--                                    <label>{{$t('items.purchase_price_last_6_invoice')}}</label>-->
          <!--                                    <input type="number" v-model="data.purchase_price_last_6_invoice" class="form-control" :class="validation && validation.purchase_price_last_6_invoice ? 'is-invalid' : ''" :placeholder="$t('items.purchase_price_last_6_invoice')"/>-->
          <!--                                    <span v-if="validation && validation.purchase_price_last_6_invoice" class="fv-plugins-message-container invalid-feedback">-->
          <!--                                            {{ validation.purchase_price_last_6_invoice[0] }}-->
          <!--                                    </span>-->
          <!--                                </div>-->
          <!--                                <div class="col-lg-6 mb-5">-->
          <!--                                    <label>{{$t('items.purchase_price_last_10_invoice')}}</label>-->
          <!--                                    <input type="number" v-model="data.purchase_price_last_10_invoice" class="form-control" :class="validation && validation.purchase_price_last_10_invoice ? 'is-invalid' : ''" :placeholder="$t('items.purchase_price_last_10_invoice')"/>-->
          <!--                                    <span v-if="validation && validation.purchase_price_last_10_invoice" class="fv-plugins-message-container invalid-feedback">-->
          <!--                                            {{ validation.purchase_price_last_10_invoice[0] }}-->
          <!--                                    </span>-->
          <!--                                </div>-->

        </b-tab>
        <b-tab v-if="$can('items.laundry_services')" :disabled="idEdit == null" :title="$t('items.laundry_services')"
               @click="toggleTab('laundry_services')">
          <div class="row">
            <div class="col-lg-12">
              <div class="card card-custom">
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="d-flex justify-content-between mt-2 p-2 bg-FFB803" style="height: 45px;">
                        <h6 class="mt-2">{{ $t('items.laundry_services') }}</h6>
                        <button class="btn btn-primary btn-sm" type="button" @click="addServices">{{
                            $t('add_more')
                          }}
                        </button>
                      </div>
                    </div>

                    <div class="col-md-12">
                      <div class="bg-white">
                        <table class="table table-row-bordered table-custom-padding" @keyup.alt.enter="addServices"
                               @keyup.alt.46="removeRowServices(0)">
                          <thead>
                          <tr>
                            <th style="width:25%">{{ $t('laundry_services_pricing.service_name') }}</th>
                            <th style="width:10%">{{ $t('laundry_services_pricing.quantity') }}</th>
                            <th style="width:10%">{{ $t('laundry_services_pricing.price') }}</th>
                            <th style="width:15%">{{ $t('laundry_services_pricing.tax') }}</th>
                            <th style="width:15%">{{ $t('laundry_services_pricing.status') }}</th>
                            <th style="width:15%"></th>
                          </tr>

                          </thead>
                          <tbody>
                          <tr v-for="(row, index) in services" :key="index">
                            <td>
                              <template v-if="!row.category_id">
                                <multiselect v-model="row.laundry_service" v-b-tooltip.hover.leftbottom
                                             :multiple="false"
                                             :options="laundry_services"
                                             :placeholder="$t('laundry_services_pricing.service_name')"
                                             :show-labels="false"
                                             :show-no-options="false"
                                             :show-no-results="false"
                                             :taggable="false"
                                             :title="$t('type_at_least_three_letters') + ' ' + $t('laundry_services_pricing.service_name')"
                                             label="name"
                                             track-by="id"
                                             @input="selectLaundryServices(index)">
                                </multiselect>
                                <span v-if="validation && validation[index+'.laundry_service']"
                                      class="fv-plugins-message-container invalid-feedback">
                                  {{ validation[index + '.laundry_service'][0] }}
                                </span>

                              </template>
                              <span v-else>{{ row.laundry_service ? row.laundry_service.name : '' }}</span>
                            </td>
                            <td>
                              <input v-model="row.qty"
                                     :class="validation && validation[index+'.qty'] ? 'is-invalid' : ''"
                                     class="form-control" step="1.0"
                                     type="number"/>
                              <span v-if="validation && validation[index+'.qty']"
                                    class="fv-plugins-message-container invalid-feedback">
                                {{ validation[index + '.qty'][0] }}
                              </span>
                            </td>
                            <td>
                              <input v-model="row.price"
                                     :class="validation && validation[index+'.price'] ? 'is-invalid' : ''"
                                     class="form-control" step="1.0"
                                     type="number"/>
                              <span v-if="validation && validation[index+'.price']"
                                    class="fv-plugins-message-container invalid-feedback">
                                {{ validation[index + '.price'][0] }}
                              </span>
                            </td>
                            <td>
                              <template v-if="!row.category_id">
                                <multiselect v-model="row.tax" v-b-tooltip.hover.leftbottom
                                             :multiple="false"
                                             :options="taxes"
                                             :placeholder="$t('sales_invoices.tax')"
                                             :show-labels="false"
                                             :show-no-options="false"
                                             :show-no-results="false"
                                             :taggable="false"
                                             :title="$t('type_at_least_three_letters') + ' ' + $t('sales_invoices.tax')"
                                             label="name"
                                             track-by="id"
                                             @input="selectLaundryTax(index)"
                                             @search-change="getTaxesFilter($event)">
                                </multiselect>
                                <span v-if="validation && validation[index+'.tax']"
                                      class="fv-plugins-message-container invalid-feedback">
                                  {{ validation[index + '.tax'][0] }}
                                </span>
                              </template>
                              <span v-else>{{ row.tax ? row.tax.name : '' }}</span>
                            </td>


                            <td>
                              <b-form-checkbox v-model="row.is_active" name="check-button" size="lg"
                                               switch></b-form-checkbox>
                            </td>
                            <td>
                              <v-icon v-if="!row.category_id" small style="color: #dc3545;"
                                      @click="removeRowServices(index)">mdi-delete
                              </v-icon>
                            </td>
                          </tr>
                          </tbody>
                        </table>

                      </div>
                    </div>

                  </div>


                  <div class="row mt-8">
                    <div class="col-md-12">
                      <div class="d-flex justify-content-between mt-2 p-2 bg-FFB803" style="height: 45px;">
                        <h6 class="mt-2">{{ $t('laundry_services_pricing.special_operations') }}</h6>
                        <button class="btn btn-primary btn-sm" type="button" @click="addOperations">{{ $t('add_more') }}
                        </button>
                      </div>
                    </div>

                    <div class="col-md-12">
                      <div class="bg-white">
                        <table class="table table-row-bordered table-custom-padding" @keyup.alt.enter="addOperations"
                               @keyup.alt.46="removeRowServices(0)">
                          <thead>
                          <tr>
                            <th style="width:25%">{{ $t('laundry_services_pricing.service_name') }}</th>
                            <th style="width:10%">{{ $t('laundry_services_pricing.operation') }}</th>
                            <th style="width:10%">{{ $t('laundry_services_pricing.quantity') }}</th>
                            <th style="width:10%">{{ $t('laundry_services_pricing.price') }}</th>
                            <th style="width:15%"></th>
                          </tr>

                          </thead>
                          <tbody>
                          <tr v-for="(row, index) in operations" :key="index">
                            <td>
                              <multiselect v-model="row.laundry_service" v-b-tooltip.hover.leftbottom
                                           :multiple="false"
                                           :options="laundry_services_with_operations"
                                           :placeholder="$t('laundry_services_pricing.service_name')"
                                           :show-labels="false"
                                           :show-no-options="false"
                                           :show-no-results="false"
                                           :taggable="false"
                                           label="name"
                                           track-by="id"
                                           @input="selectLaundryServicesOperation(index)">
                              </multiselect>
                            </td>
                            <td>
                              <multiselect v-model="row.special_operation" v-b-tooltip.hover.leftbottom
                                           :multiple="false"
                                           :options="(row.laundry_service && row.laundry_service.operations) ? row.laundry_service.operations : []"
                                           :placeholder="$t('laundry_services_pricing.operation')"
                                           :show-labels="false"
                                           :show-no-options="false"
                                           :show-no-results="false"
                                           :taggable="false"
                                           label="name"
                                           track-by="id"
                                           @input="selectOperation(index)">
                              </multiselect>
                            </td>
                            <td>
                              <input v-model="row.qty"
                                     :class="validation && validation[index+'.qty'] ? 'is-invalid' : ''"
                                     class="form-control" step="1.0"
                                     type="number"/>

                            </td>
                            <td>
                              <input v-model="row.price"
                                     :class="validation && validation[index+'.price'] ? 'is-invalid' : ''"
                                     class="form-control" step="1.0"
                                     type="number"/>

                            </td>

                            <td>
                              <v-icon small style="color: #dc3545;" @click="removeRowOperation(index)">mdi-delete
                              </v-icon>
                            </td>
                          </tr>
                          </tbody>
                        </table>

                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-tab>
        <b-tab :disabled="idEdit == null" :title="$t('items.inventory_details')"
               @click="toggleTab('inventory_details')">

          <div class="row">
            <div class="col-lg-12">
              <div class="card card-custom">
                <div class="card-body">
                  <div class="bg-white">
                    <table class="table table-custom-padding">
                      <thead>

                      <tr>
                        <th>{{ $t('inventory_statistics.inventory') }}</th>
                        <th>{{ $t('inventory_statistics.product') }}</th>
                        <th>{{ $t('inventory_statistics.financial_year') }}</th>
                        <th>{{ $t('inventory_statistics.sales_booking') }}</th>
                        <th>{{ $t('inventory_statistics.available_stock_qty') }}</th>
                        <th>{{ $t('inventory_statistics.open_stock_quantity_total') }}</th>
                        <th>{{ $t('inventory_statistics.stock_on_hand_qty') }}</th>
                        <th>{{ $t('inventory_statistics.purchase_on_way') }}</th>
                        <th>{{ $t('inventory_statistics.max_quantity') }}</th>
                        <th>{{ $t('inventory_statistics.min_quantity') }}</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(statistic, index) in statistics_item" :key="'statistic'+index">
                        <td>{{ statistic.inventory }}</td>
                        <td>{{ statistic.product }}</td>
                        <td>{{ statistic.financial_year }}</td>
                        <td>{{ statistic.sales_booking }}</td>
                        <td>{{ statistic.available_stock_qty }}</td>
                        <td>{{ statistic.open_stock_quantity_total }}</td>
                        <td>{{ statistic.stock_on_hand_qty }}</td>
                        <td>{{ statistic.purchase_on_way }}</td>
                        <td>
                          <input v-model="statistic.max_quantity" class="form-control" type="text"/>
                        </td>
                        <td>
                          <input v-model="statistic.min_quantity" class="form-control" type="text"/>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </b-tab>
        <b-tab v-if="idEdit" :title="$t('activity_log.activity_log')" @click="toggleTab('activity_log')">
          <br>

          <div class="card card-custom">
            <div class="card-body row">
              <item-quantity-activity-log :id="idEdit"></item-quantity-activity-log>
            </div>
          </div>
        </b-tab>
        <b-tab v-if="idEdit" :title="$t('add_quantities')" @click="toggleTab('add_quantities')">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="col-xl-4 col-md-6 mb-5">
                <label>{{ $t('operation_date') }}<span class="text-danger">*</span></label>
                <input v-model="add_quantity_data.transaction_date" :class="quantity_form_validation && quantity_form_validation.transaction_date ? 'is-invalid' : ''" :placeholder="$t('operation_date')"
                       class="form-control"
                       type="date"/>
                <span v-if="quantity_form_validation && quantity_form_validation.transaction_date"
                      class="fv-plugins-message-container invalid-feedback">
                  {{ quantity_form_validation.transaction_date[0] }}
                </span>
              </div>
              <div class="col-xl-4 col-md-6 mb-5">
                <label>{{ $t('inventory_transactions.inventory') }}<span class="text-danger">*</span></label>
                <multiselect v-model="inventory_obj"
                             :class="quantity_form_validation && quantity_form_validation.inventory_id ? 'is-invalid' : ''"
                             :multiple="false"
                             :options="inventories"
                             :placeholder="$t('inventory_transactions.inventory')"
                             :show-labels="false"
                             :show-no-options="false"
                             :show-no-results="false"
                             :taggable="false"
                             label="name"
                             track-by="id"
                             @select="selectInventory">
                </multiselect>
                <span v-if="quantity_form_validation && quantity_form_validation.inventory_id"
                      class="fv-plugins-message-container invalid-feedback">
                  {{ quantity_form_validation.inventory_id[0] }}
                </span>

              </div>
              <div class="col-xl-4 col-md-6 mb-5">
                <label>{{ $t('inventory_transactions.unit') }}<span class="text-danger">*</span></label>
                <multiselect v-model="unit_obj"
                             :placeholder="$t('inventory_transactions.unit')"
                             label="name"
                             track-by="id"
                             :options="units_list_for_qty? units_list_for_qty : []"
                             :class="quantity_form_validation && quantity_form_validation.unit_id ? 'is-invalid' : ''"
                             :multiple="false"
                             :taggable="false"
                             :show-labels="false"
                             :show-no-options="false"
                             :show-no-results="false"
                             @select="selectUnit">
                </multiselect>
                <span v-if="quantity_form_validation && quantity_form_validation.unit_id"
                      class="fv-plugins-message-container invalid-feedback">
                  {{ quantity_form_validation.unit_id[0] }}
                </span>
              </div>
              <div class="col-xl-4 col-md-6 mb-5" v-if="(add_quantity_data.unit_id != data.unit_id) && add_quantity_data.unit_id">
                <label>{{ $t('units_number') }}<span class="text-danger">*</span></label>
                <input v-model="add_quantity_data.units_number" @input="setQtyValue" :class="quantity_form_validation && quantity_form_validation.units_number ? 'is-invalid' : ''" :placeholder="$t('units_number')"
                       class="form-control"
                       type="number"/>
                <span v-if="quantity_form_validation && quantity_form_validation.units_number"
                      class="fv-plugins-message-container invalid-feedback">
                  {{ quantity_form_validation.units_number[0] }}
                </span>
              </div>
              <div class="col-xl-4 col-md-6 mb-5">
                <label>{{ $t('categories.quantity') }}<span class="text-danger">*</span></label>
                <input v-model="add_quantity_data.quantity" @input="setQtyValue" :disabled="add_quantity_data.unit_id != data.unit_id && add_quantity_data.unit_id" :class="quantity_form_validation && quantity_form_validation.quantity ? 'is-invalid' : ''" :placeholder="$t('categories.quantity')"
                       class="form-control"
                       type="number"/>
                <span v-if="quantity_form_validation && quantity_form_validation.quantity"
                      class="fv-plugins-message-container invalid-feedback">
                  {{ quantity_form_validation.quantity[0] }}
                </span>
              </div>


              <div class="col-xl-4 col-md-6 mb-5">
                <label>{{ $t('items.purchase_price') }}</label>
                <input v-model="add_quantity_data.purchase_price" @input="setQtyValue" :class="quantity_form_validation && quantity_form_validation.purchase_price ? 'is-invalid' : ''" :placeholder="$t('items.purchase_price')"
                       class="form-control"
                       type="number"/>
                <span v-if="quantity_form_validation && quantity_form_validation.purchase_price"
                      class="fv-plugins-message-container invalid-feedback">
                  {{ quantity_form_validation.purchase_price[0] }}
                </span>
              </div>
              <div class="col-12 mb-5">
                <label>{{ $t('inventory_transactions.notes') }}</label>
                <textarea type="text" v-model="add_quantity_data.notes" class="form-control" :class="quantity_form_validation && quantity_form_validation.notes ? 'is-invalid' : ''" :placeholder="$t('inventory_transactions.notes')"></textarea>
                <span v-if="quantity_form_validation && quantity_form_validation.notes" class="fv-plugins-message-container invalid-feedback">
                  {{ quantity_form_validation.notes[0] }}
                </span>
              </div>
            </div>
          </div>
        </b-tab>
      </b-tabs>


      <div v-if="tab_name != 'activity_log' && tab_name != 'add_quantities'" class="pl-0 pr-0">
        <div class="row">
          <div class="col-md">
            <button class="btn btn-primary mr-2 mt-3" type="button" @click="save(1, $event)">{{ $t('save') }}</button>
            <button v-if="!isEditing" class="btn btn-primary mr-2 mt-3" type="button" @click="save(2, $event)">{{ $t('save_and_create_new') }}</button>
            <button v-if="!isEditing" class="btn btn-primary mr-2 mt-3" type="button" @click="save(3, $event)">{{ $t('save_and_redirect_to_main_page') }}</button>
          </div>
          <div class="col-md-auto">
            <button v-if="data.next_id" class="btn btn-warning mt-3" type="button" @click="loadNextItem">{{ $t('next_item') }}</button>
            <button v-if="data.previous_id" class="btn btn-warning ml-2 mt-3" type="button" @click="loadPreviousItem">{{ $t('previous_item') }}</button>
          </div>
        </div>
      </div>

      <div v-if="tab_name === 'add_quantities'" class="pl-0 pr-0">
        <div class="row pt-3">
          <div class="col-md">
            <button class="btn btn-primary mr-2 mt-3" type="button" @click="saveQuantity($event)">{{ $t('save') }}</button>
<!--            <button v-if="!isEditing" class="btn btn-primary mr-2 mt-3" type="button" @click="saveQuantity($event)">{{ $t('save_and_create_new') }}</button>-->
<!--            <button v-if="!isEditing" class="btn btn-primary mr-2 mt-3" type="button" @click="saveQuantity($event)">{{ $t('save_and_redirect_to_main_page') }}</button>-->
          </div>
          <div class="col-md-auto">
            <button v-if="data.next_id" class="btn btn-warning mt-3" type="button" @click="loadNextItem">{{ $t('next_item') }}</button>
            <button v-if="data.previous_id" class="btn btn-warning ml-2 mt-3" type="button" @click="loadPreviousItem">{{ $t('previous_item') }}</button>
          </div>
        </div>
      </div>

    </div>
    <!--end::User-->
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import ItemQuantityActivityLog from "@/view/content/widgets/details/ItemQuantityActivityLog";
import {mapGetters, mapState} from "vuex";
import VueBarcode from '@chenfengyuan/vue-barcode';
import dataTypeNumbers from "@/core/config/mix/dataTypeNumbers";


export default {
  name: "from-item",
  components: {'item-quantity-activity-log': ItemQuantityActivityLog, VueBarcode},
  data() {
    return {
      mainRoute: 'items/items',
      mainRouteDependency: 'base/dependency',
      mainRouteLaundryServices: 'settings/categories_list/laundry_services',
      key: 'Items',
      dir_upload: 'items',
      categories: [],
      brands: [],
      tags_list: [],
      taxes: [],
      discount_type_list: [
        {id: 1, name: this.$t('discount_type.percentage')},
        {id: 2, name: this.$t('discount_type.value')},
      ],
      scale_type_list: [],
      apply_priority_list: [
        {id: 1, name: 1},
        {id: 2, name: 2},
        {id: 3, name: 3},
        {id: 4, name: 4},
        {id: 5, name: 5},
      ],

      idEdit: this.$route.params.id ? this.$route.params.id : null,
      // Start Add quantities form
      add_quantity_data: {
        transaction_date: null,
        inventory_id: null,
        item_id: this.$route.params.id ? this.$route.params.id : null,
        unit_id: null,
        quantity: null,
        units_number: null,
        purchase_price: null,
        transaction_type: 1,
        notes: null,
      },
      quantity_form_validation: null,
      add_quantities_tab_loaded: false,
      inventories: [],
      inventory_obj: null,
      all_units: [],
      unit_obj: null,
      // End Add quantities form
      data: {
        name: null,
        category_id: null,
        brand_id: null,
        description: null,
        special_description: null,
        sku_code: null,
        barcode: null,
        image: null,
        tags: [],
        is_active: true,
        is_manufacture: true,
        is_tracking: false,
        is_committed: false,
        re_order: null,
        scale_type: 1,
        purchase_price: null,
        raw_material_price: null,
        purchase_price_last_3_invoice: null,
        purchase_price_last_6_invoice: null,
        purchase_price_last_10_invoice: null,
        sale_price: null,
        min_sale_price: null,
        profit_margin: null,
        discount: null,
        discount_type: 1,
        wholesale_price: null,
        half_wholesale_price: null,
        branch_price: null,
        initial_stock_level: null,
        low_stock_threshold: null,
        minimum_qty: null,
        item_type: 1,
        total_taxes: 0,
        mrp: null,
        unit_id: null,
        allowable_return_days: null,
        related_item_ids: [],
        next_id: null,
        previous_id: null,
        is_min: false
      },
      categoriSelectedType: 0,
      item_barcodes: [],
      isEditing: this.$route.params.id ? true : false,
      validation: null,
      tax_default: null,
      image_url: null,
      reloadUploadAttachment: true,
      repeater_taxes: {id: null, tax: null, percentage: null, priority: null, minimum: 0},

      data_taxes: [],
      data_barcodes: [],

      repeater_barcodes: {id: null, barcode: null},
      brand: null,
      tab_name: 'basic_information',
      PlanMessage: null,
      unit: null,
      units: [],
      statistics_item: [],
      related_items: [],
      related_item_data: null,
      f_by: 'name',

      /**
       * laundry service data
       */
      service_from: {
        id: null,
        item_id: this.$route.params.id ? this.$route.params.id : null,
        laundry_service: null,
        laundry_service_id: null,
        price: null,
        qty: 1,
        is_active: null,
        is_manually_added: true,
        category_id: null,
        tax_id: null,
        tax: null,
      },
      services: [],
      laundry_services: [],
      laundry_services_with_operations: [],
      operations_repeater: {
        item_id: this.$route.params.id ? this.$route.params.id : null,
        laundry_service: null,
        laundry_service_id: null,
        special_operation: null,
        special_operation_id: null,
        price: null,
        qty: 1,
      },
      operations: [],
      repeater_unit: {unit: null, unit_id: null, number: null, is_active: true},
      data_units: [],
      is_committed_sales_disabled: true,
      repeater_validation: [],
      units_list_for_qty: [],
    }
  },
  computed: {
    ...mapState({user_personal_info: state => state.profile.user_personal_info}),
    ...mapGetters(["currentUserPersonalInfo"]),

  },
  watch: {
    tab_name: function (val) {
      if (val === 'add_quantities' && !this.add_quantities_tab_loaded) {
        this.getInventories();
        this.getAllUnits();
        this.add_quantities_tab_loaded = true;
      }
    },
    brand: function (val) {
      if (val && val.id) {
        this.data.brand_id = val.id;
      } else {
        this.data.brand_id = null;
      }

    },
    // watch category_id inside data
    'data.category_id': function (val) {
      if (val) {
        //   change categoriSelectedType instead of data.categoryes.type
        this.categoriSelectedType = this.categories.find((category) => category.id === val).type;
      }
    },
    unit: function (val) {
      if (val && val.id) {
        this.data.unit_id = val.id;
      } else {
        this.data.unit_id = null;
      }

    },
    data_taxes: {
      handler(val) {
        if (val.length > 0) {
          this.calcPercentageForTotal();
        }
      },
      deep: true
    },
  },
  methods: {
    ...dataTypeNumbers,
    scrollTop() {
    //   scroll top smothly
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    },
    loadNextItem(){
      history.pushState({}, null, '/items/items/edit/'+this.data.next_id);
      let promise = this.getData(this.data.next_id);
      Promise.resolve(promise).then(() => {
        this.scrollTop();
      });

    },
    loadPreviousItem(){
      history.pushState({}, null, '/items/items/edit/'+this.data.previous_id);
      let promise = this.getData(this.data.previous_id);
      Promise.resolve(promise).then(() => {
        this.scrollTop();
      });
    },
    validationQTYChecking(_status = true) {

      this.quantity_form_validation = [];

        let _validation_status = false;

        // if (parseFloat(this.add_quantity_data.purchase_price) <= 0) {
        //   this.quantity_form_validation.purchase_price = [];
        //   this.quantity_form_validation.purchase_price[0] = this.$t('the_value_must_not_be_less_than_0');
        //   _validation_status = true;
        // }else if(!this.add_quantity_data.purchase_price && _status){
        //   this.quantity_form_validation.purchase_price = [];
        //   this.quantity_form_validation.purchase_price[0] = this.$t('the_value_must_not_be_less_than_0');
        //   _validation_status = true;
        // }

        if (parseFloat(this.add_quantity_data.quantity) <= 0) {
          this.quantity_form_validation.quantity = [];
          this.quantity_form_validation.quantity[0] = this.$t('the_value_must_not_be_less_than_0');
          _validation_status = true;
        }else if(!this.add_quantity_data.quantity && _status){
          this.quantity_form_validation.quantity = [];
          this.quantity_form_validation.quantity[0] = this.$t('the_value_must_not_be_less_than_0');
          _validation_status = true;
        }


       if(!this.add_quantity_data.unit_id && _status){
          this.quantity_form_validation.unit_id = [];
          this.quantity_form_validation.unit_id[0] = this.$t('the_unit_field_must_be_required');
          _validation_status = true;
        }

       if(!this.add_quantity_data.inventory_id && _status){
          this.quantity_form_validation.inventory_id = [];
          this.quantity_form_validation.inventory_id[0] = this.$t('the_field_must_be_required', {'field_name': this.$t('inventory_transactions.inventory')});
          _validation_status = true;
        }

       if(!this.add_quantity_data.transaction_date && _status){
          this.quantity_form_validation.transaction_date = [];
          this.quantity_form_validation.transaction_date[0] = this.$t('the_field_must_be_required', {'field_name': this.$t('operation_date')});
          _validation_status = true;
        }

      if (_validation_status){
        if (_status)
          this.$errorAlertMessage(this.$t('unable_to_send_due_to_data_entry_error'));
      }
      return _validation_status;

    },

    saveQuantity(event) {
      if (this.validationQTYChecking()){
        event.preventDefault();
        return false;
      }else{
        ApiService.post('items/transaction/add_item', this.add_quantity_data).then((response) => {
          this.quantity_form_validation = null;
          this.$successAlert(response.data.message);
          this.$router.push('/items/items');
        }).catch((error) => {
          this.$errorAlert(error);
          this.quantity_form_validation = error.response ? error.response.data.errors : null;
        });
      }

    },
    getAllUnits() {
      ApiService.get(this.mainRouteDependency + "/units").then((response) => {
        this.all_units = response.data.data;
      });
    },
    selectUnit(value) {
      this.add_quantity_data.unit_id = value.id;
      this.add_quantity_data.units_number = 0;
      this.setQtyValue();
    },
    setQtyValue() {
      if (isNaN(this.add_quantity_data.units_number)) {
        this.add_quantity_data.units_number = 0;
      } else if (parseFloat(this.add_quantity_data.units_number) < 0) {
        this.add_quantity_data.units_number = 0;
      }

      if (isNaN(this.add_quantity_data.quantity)) {
        this.add_quantity_data.quantity = 0;
      } else if (parseFloat(this.add_quantity_data.quantity) < 0) {
        this.add_quantity_data.quantity = 0;
      }

      if (isNaN(this.add_quantity_data.purchase_price)) {
        this.add_quantity_data.purchase_price = 0;
      } else if (parseFloat(this.add_quantity_data.purchase_price) < 0) {
        this.add_quantity_data.purchase_price = 0;
      }

        if (this.add_quantity_data.unit_id != this.data.unit_id) {
          if (this.data.is_min) {
            this.add_quantity_data.quantity = ((this.unit_obj.number ? parseFloat(this.unit_obj.number) : 0) * (this.add_quantity_data.units_number ? parseFloat(this.add_quantity_data.units_number) : 0)).toFixed(3);
          } else {
            this.add_quantity_data.quantity = ((this.add_quantity_data.units_number ? parseFloat(this.add_quantity_data.units_number) : 0) / (this.unit_obj.number ? parseFloat(this.unit_obj.number) : 0)).toFixed(3);
          }
        } else {
          this.add_quantity_data.units_number = 0;
        }


    },
    selectInventory(value) {
      this.add_quantity_data.inventory_id = value.id;
    },
    getInventories() {
      ApiService.get(this.mainRouteDependency + "/inventories").then((response) => {
        this.inventories = response.data.data;
      });
    },
    getScaleTypesList() {
      ApiService.get(this.mainRouteDependency + '/scale_type').then((response) => {
        this.scale_type_list = response.data.data;
      });
    },
    getRelatedItemById() {
      // if (typeof this.data.role_id !== 'undefined') {
      if (this.related_item_data) {
        let item_ids = [];
        this.related_item_data.filter((role) => {
          item_ids.push(role.id);
        });
        this.data.related_item_ids = item_ids;
      }
      // }
    },
    downloadBarcodeImage() {
      const vueBarcode = document.getElementById('vueBarcode');
      const link = document.createElement('a');
      link.href = vueBarcode.src;
      link.download = this.data.barcode;
      link.click();
    },


    toggleTab(tab_name) {
      this.tab_name = tab_name
    },
    checkingUnitsRepeater(_status = true){
      let _main_validation_status = false;
      this.validation = [];
      this.data_units.forEach((row, index) => {
        let _validation_status = false;
        if(!row.unit && _status){
          this.validation[`units_list.${index}.unit`] = [];
          this.validation[`units_list.${index}.unit`][0] = this.$t('the_unit_field_must_be_required');
          _validation_status = true;
        }

        if(this.data.is_min){
          if (parseFloat(this.data_units[index].number) > 999999.999){
            this.validation[`units_list.${index}.number`] = [];
            this.validation[`units_list.${index}.number`][0] = this.$t('the_number_field_value_of_unit_with_is_min');
            _validation_status = true;
          }
          if (parseFloat(this.data_units[index].number) < 1.001){
            this.validation[`units_list.${index}.number`] = [];
            this.validation[`units_list.${index}.number`][0] = this.$t('the_number_field_value_of_unit_with_is_min');
            _validation_status = true;
          }
          if (parseFloat(this.data_units[index].number)  == 1){
            this.validation[`units_list.${index}.number`] = [];
            this.validation[`units_list.${index}.number`][0] = this.$t('the_number_field_value_of_unit_should_not_be_1');
            _validation_status = true;
          }
        }else{
          if (parseFloat(this.data_units[index].number) > 999999.999){
            this.validation[`units_list.${index}.number`] = [];
            this.validation[`units_list.${index}.number`][0] = this.$t('the_number_field_value_of_unit');
            _validation_status = true;

          }
          if (parseFloat(this.data_units[index].number) < 0.001){
            this.validation[`units_list.${index}.number`] = [];
            this.validation[`units_list.${index}.number`][0] = this.$t('the_number_field_value_of_unit');
            _validation_status = true;
          }
          if (parseFloat(this.data_units[index].number)  == 1){
            this.validation[`units_list.${index}.number`] = [];
            this.validation[`units_list.${index}.number`][0] = this.$t('the_number_field_value_of_unit_should_not_be_1');
            _validation_status = true;
          }
        }

        if (_validation_status && _status){
          let _index = this.repeater_validation.indexOf(index);
          if (_index < 0) {
            this.repeater_validation.push(index);
          }
        }else{
          let _index = this.repeater_validation.indexOf(index);
          if (_index > -1) {
            this.repeater_validation.splice(_index, 1);
          }
        }

      });
      if (this.repeater_validation.length > 0){
        _main_validation_status = true;
      }
      if (_main_validation_status){
        let _units = [...this.data_units];
        this.data_units = [];
        this.data_units = _units;
        if (_status)
          this.$errorAlertMessage(this.$t('unable_to_send_due_to_data_entry_error'));
      }

      return _main_validation_status;
    },

    save(_action, event) {
      if(this.checkingUnitsRepeater()){
        event.preventDefault();
        return false;
      }else{
        if (this.isEditing) {
          this.update();
        } else {
          this.create(_action);
        }

      }
    },
    redirectActionAfterSave(_action){
      switch (_action){
        case 1:
          this.$router.push({name: 'items.edit', params: {id: this.idEdit}}, () => {
            window.location.reload();
          });
          break;
        case 2:
          this.$router.push({query: {_pgid: Date.now()}});
          break;
        case 3:
          this.$router.push({name: 'items.index'});
          break;
        default:
          this.$router.push({name: 'items.edit', params: {id: this.idEdit}}, () => {
            window.location.reload();
          });
          break;
      }
    },



    create(_action) {
      // this.data.is_active = this.data.is_active ? '1' : '0';
      // this.data.is_manufacture = this.data.is_manufacture ? '1' : '0';
      // this.data.is_tracking = this.data.is_tracking ? '1' : '0';
      let $data = this.data;
      if (this.categoriSelectedType == 0) {
        delete $data.scale_type;
      }
      let _units = [...this.data_units];
      _units = _units.map((row)=>{
        return {unit_id: row.unit_id, number: row.number, is_active: row.is_active}
      });
      _units = _units.filter((row)=> parseFloat(row.number) > 0);

      ApiService.post(this.mainRoute, {
        ...$data,
        item_barcodes: this.data_barcodes,
        taxes: this.data_taxes,
        laundry: this.services,
        tab_name: this.tab_name,
        special_operations: this.operations,
        units: _units,
      }).then((response) => {
        this.validation = null;
        this.$successAlert(response.data.message);
        this.idEdit = response.data.data.id;
        // if (response.data.data.id){
        //     this.getData();
        // }
        // this.$router.push({name: 'items.edit', params:{id: response.data.data.id}});
        // this.$router.push({name: 'items.edit', params: {id: response.data.data.id}}, () => {
        //   window.location.reload();
        // });
        this.redirectActionAfterSave(_action);
      }).catch((error) => {
        this.$errorAlert(error);
        this.validation = error.response ? error.response.data.errors : null;
      });
    },

    update() {
      // this.data.is_active = this.data.is_active ? '1' : '0';
      // this.data.is_manufacture = this.data.is_manufacture ? '1' : '0';
      // this.data.is_tracking = this.data.is_tracking ? '1' : '0';
      let $data = this.data;
      if (this.categoriSelectedType == 0) {
        delete $data.scale_type;
      }
      let _units = [...this.data_units];
      _units = _units.map((row)=>{
        return {unit_id: row.unit_id, number: row.number, is_active: row.is_active}
      });
      _units = _units.filter((row)=> parseFloat(row.number) > 0);

          ApiService.put(this.mainRoute + '/' + this.idEdit, {
        ...$data,
        taxes: this.data_taxes,
        statistics_item: this.statistics_item,
        laundry: this.services,
        tab_name: this.tab_name,
        special_operations: this.operations,
        units: _units,
      }).then((response) => {
        this.validation = null;
        this.$successAlert(response.data.message);
        this.$router.push('/items/items');
      }).catch((error) => {
        this.$errorAlert(error);
        this.validation = error.response ? error.response.data.errors : null;
      });
    },

    async getData(id) {
      this.reloadUploadAttachment = false;
     await ApiService.get(this.mainRoute + '/' + id).then((response) => {
        this.isEditing = true;
        this.data = response.data.data;
        this.brand = response.data.data.brand;
        this.unit = response.data.data.unit;
        this.image_url = response.data.data.image_url;
        this.data_taxes = response.data.data.taxes;
        this.operations = response.data.data.special_operations ? response.data.data.special_operations : [];
        this.data_units = response.data.data.units ? response.data.data.units : [];

        // if (response.data.data.unit){
        //   this.units_list_for_qty.push({
        //     id: response.data.data.unit.id,
        //     name: response.data.data.unit.name,
        //     unit_id: response.data.data.unit.id,
        //     number: 1,
        //     is_active: true,
        //     is_main: true,
        //     unit: {
        //       id: response.data.data.unit.id,
        //       name: response.data.data.unit.name,
        //     }
        //   })
        // }
       this.units_list_for_qty.push(...response.data.data.item_units);

        this.getRelatedItemById();

        if (response.data.data.taxes.length <= 0) {
          this.addToRepeater();
        }

        this.data_barcodes = response.data.data.item_barcodes;
        // console.log(response.data.data,response.data.data.barcodes,this.data_barcodes);
        // this.data_barcodes = response.data.data.item_barcodes.map((item) => {
        //   console.log(item);
        //     return {
        //       // id: item.id,
        //       barcode: item,
        //     };
        //   });


        // console.log('length',response.data.data.item_barcodes.length <= 0);

        if (response.data.data.item_barcodes.length <= 0) {
          // console.log(response.data.data.item_barcodes);
          // console.log(this.data_barcodes);
          this.addBarcodeToRepeater();
        }
        if (response.data.data && (!response.data.data.laundry || response.data.data.laundry.length == 0)) {
          this.addServices();
        } else {
          this.services = response.data.data.laundry;
        }

        this.reloadUploadAttachment = true;
      });
    },

    getClassValidationRepeaterByIndex(index) {
      if (index != null) {
        if (this.repeater_validation.includes(index))
            // return 'tr-validation-error';
          return 'border-validation';
      }
      return '';
    },
    // getItems() {
    //   ApiService.get("base/dependency/items").then((response) => {
    //     this.related_items = response.data.data.map((item) => {
    //       return {
    //         id: item.id,
    //         name: item.name,
    //       };
    //     });
    //   });
    // },

    getItems(f_by = null, filter = null) {
      let _f_by = f_by ? f_by : 'name';
      if (filter && filter.length >= 3 && _f_by)
        ApiService.get(this.mainRouteDependency + "/items", {
          params: {
            [_f_by]: filter,
            item_type: 1
          }
        }).then((response) => {
          // this.related_items = response.data.data;

          this.related_items = response.data.data.map((item) => {
            return {
              id: item.id,
              name: item.name,
            };
          });

        });
    },

    getCategories() {
      ApiService.get(this.mainRouteDependency + "/categories").then((response) => {
        this.categories = response.data.data;
      });
    },
    getBrands() {
      ApiService.get(this.mainRouteDependency + "/brands").then((response) => {
        this.brands = response.data.data;
      });
    },
    getTaxes() {
      ApiService.get(this.mainRouteDependency + "/taxes").then((response) => {
        this.taxes = response.data.data;
      });
    },
    // async getDefaultTax() {
    //     await ApiService.get(`${this.mainRouteDependency}/default-tax`).then((response) => {
    //         this.tax_default = response.data.data;
    //     });
    // },
    updateValue(value) {
      if (value) {
        // this.unit = value.unit;
        // this.data.unit_id = value.unit_id;
        this.data.category_id = value.id;
        this.data.allowable_return_days = value.allowable_return_days;
        // this.categoriSelectedType = value.type;
        this.getLaundryServicesForCategory(value.id);
      }
    },
    loadOptions() {
    },
    listenerAttachment(event) {
      if (event) {
        this.image_url = event.pathDB;
        this.data.image = event.name;
      }
    },
    addTag(newTag) {
      const tag = {
        name: newTag,
        id: Math.floor((Math.random() * 10000000))
      }
      if (!this.tags_list) {
        this.tags_list = [];
      }
      this.tags_list.push(tag);

      if (!this.data.tags) {
        this.data.tags = [];
      }
      this.data.tags.push(tag)
    },
    addToRepeater() {
      // this.repeater_taxes.tax = this.tax_default;
      this.repeater_taxes.priority = this.data_taxes.length + 1;
      this.data_taxes.unshift(this.repeater_taxes);
      this.repeater_taxes = {id: null, tax: this.user_personal_info.tax, percentage: null, priority: null, minimum: 0};
    },
    deleteFromRepeater(index) {
      this.data_taxes.splice(index, 1);
      this.data_taxes = this.data_taxes.map((row, index) => {
        row.priority = this.data_taxes.length - index;
        return row;
      });
      this.calcPercentageForTotal();
    },

    addUnitToRepeater() {
      this.data_units.unshift(this.repeater_unit);
      this.repeater_unit = {unit: null, unit_id: null, number: null, is_active: true};
    },
    deleteUnitFromRepeater(index) {
      this.data_units.splice(index, 1);
    },
    onChangeUnitsRepeater: _.debounce(function (index){
      if (this.data_units[index].unit){
        this.data_units[index].unit_id = this.data_units[index].unit.id;
        if (this.data_units[index].unit_id == this.data.unit_id){
          this.data_units[index].unit = null;
          this.data_units[index].unit_id = null;
        }
      }else{
        this.data_units[index].unit_id = null;
      }

      let _attr = String(this.data_units[index].number).split('.');
      if (_attr[1] && _attr[1].length > 3){
        this.data_units[index].number = parseFloat(this.data_units[index].number).toFixed(3);
      }

      if (isNaN(this.data_units[index].number)) {
        this.data_units[index].number = 0;
      }
      if (parseFloat(this.data_units[index].number) > 999999.999){
        this.data_units[index].number = 999999.999;
      }
      if (parseFloat(this.data_units[index].number) < 0){
        this.data_units[index].number = 0;
      }
    }, 300),

    // onChangeIsMin: _.debounce(function () {
    //   if(this.data.is_min){
    //     this.data_units = this.data_units.map((row)=>{
    //       if (!row.number || (row.number && parseFloat(row.number) < 1.001)) {
    //         row.number = 1.001;
    //       }
    //
    //       return row;
    //     })
    //   }
    // }, 100),

    onPriorityTaxChanged: _.debounce(function (index) {
      if ((this.data_taxes.length - 1) == index) {
        this.data_taxes[index].priority = 1;
      } else {
        this.data_taxes[index].priority = this.data_taxes[index + 1].priority + 1
      }

    }, 100),


    addBarcodeToRepeater() {
      this.data_barcodes.unshift(this.repeater_barcodes);
      this.repeater_barcodes = {id: null, barcode: null};
    },

    deleteBarcodeFromRepeater(index) {
      this.data_barcodes.splice(index, 1);
    },

    deleteBarcodeItem(item) {
      // this.$confirmAlert('', this.actionDelete, item);
      // console.log(item);
    },

    handlingPercentageValue(index) {
      let _value_rate = this.data_taxes[index].tax.value_rate;
      this.data_taxes[index].percentage = _value_rate;
    },
    calcPercentageForTotal() {
      // if (this.data_taxes.length > 0) {
      //   this.data.total_taxes = this.$_.sumBy(this.data_taxes, (row) => {
      //     return row.percentage ? parseFloat(row.percentage) : 0;
      //   });
      // } else {
      //   this.data.total_taxes = 0;
      // }
    },
    getCode() {
      ApiService.get(this.mainRouteDependency + "/code-setting/9").then((response) => {
        this.data.sku_code = response.data.data.code;
      });
    },

    getPlanMessage() {
      ApiService.get(this.mainRouteDependency + `/check_limits/items`).then((response) => {
        this.PlanMessage = response.data.data;
      });
    },

    getUnits() {
      ApiService.get(`${this.mainRouteDependency}/units`).then((response) => {
        this.units = response.data.data;
      });
    },

    getStatisticsItem() {
      if (this.idEdit) {
        ApiService.get(`settings/inventory_statistics`, {
          params: {
            is_paginate: 0,
            product_id: this.idEdit
          }
        }).then((response) => {
          this.statistics_item = response.data.data;
        });
      }
    },

    getGenerateBarcode() {
      ApiService.get(`${this.mainRouteDependency}/generate_barcode`).then((response) => {
        this.data.barcode = response.data.data[0] ? response.data.data[0] : '';
      });
    },
    async defaultDataForUser() {
      await ApiService.get(this.mainRouteDependency + "/default_data_for_user").then((response) => {
        this.repeater_taxes.tax = response.data.data.tax;
        if (this.data_taxes[0]) {
          this.data_taxes[0].tax = response.data.data.tax;
          this.data_taxes[0].percentage = response.data.data.tax.value_rate ? response.data.data.tax.value_rate : 0;
          this.data_taxes[0].priority = 1;
        }
      });
    },


    /**
     *
     * Laundry Services functions
     */

    addServices() {
      this.services.unshift(this.service_from);
      this.service_from = {
        id: null,
        item_id: this.$route.params.id ? this.$route.params.id : null,
        laundry_service_id: null,
        laundry_service: null,
        price: null,
        qty: 1,
        is_active: null,
        is_manually_added: true,
        category_id: null,
        tax_id: null,
        tax: null,
      };
    },
    removeRowServices(index) {
      this.services.splice(index, 1);
    },
    selectLaundryServices(index) {
      if (this.services[index].laundry_service && this.services[index].laundry_service.id) {
        this.services[index].laundry_service_id = this.services[index].laundry_service.id;
      }
    },
    selectLaundryTax(index) {
      if (this.services[index].tax && this.services[index].tax.id) {
        this.services[index].tax_id = this.services[index].tax.id;
      }
    },
    selectLaundryServicesOperation(index) {
      this.operations[index].special_operation = null;
      this.operations[index].special_operation_id = null;

      if (this.operations[index].laundry_service && this.operations[index].laundry_service.id) {
        this.operations[index].laundry_service_id = this.operations[index].laundry_service.id;
      }
    },
    addOperations() {
      this.operations.unshift(this.operations_repeater);
      this.operations_repeater = {
        item_id: this.$route.params.id ? this.$route.params.id : null,
        laundry_service: null,
        laundry_service_id: null,
        special_operation: null,
        special_operation_id: null,
        price: null,
        qty: 1,
      };
    },
    selectOperation(index) {
      if (this.operations[index].special_operation && this.operations[index].special_operation.id) {
        this.operations[index].special_operation_id = this.operations[index].special_operation.id;
      }
    },
    removeRowOperation(index) {
      this.operations.splice(index, 1);
    },
    getTaxesFilter(filter) {
      if (filter && filter.length >= 3) {
        ApiService.get(`${this.mainRouteDependency}/taxes`, {params: {filter: filter}}).then((response) => {
          this.taxes = response.data.data;
        });
      } else {
        this.taxes = [];
      }
    },
    getLaundryServices() {
      ApiService.get(`${this.mainRouteDependency}/laundry_services`).then((response) => {
        this.laundry_services = response.data.data;
        this.laundry_services_with_operations = response.data.data.filter((row) => row.operations.length > 0);
      });
    },
    async getLaundryServicesForCategory(category_id) {
      if (category_id)
        ApiService.get(`${this.mainRouteLaundryServices}/list/${category_id}`).then((response) => {
          if (response.data.data && response.data.data.length > 0) {
            this.services = response.data.data ? response.data.data.map((row) => {
              return {
                id: null,
                laundry_service: row.laundry_service,
                price: row.price,
                qty: row.qty,
                tax: row.tax,
                is_active: row.is_active,
                is_manually_added: false,
                category_id: row.category_id ? row.category_id : null
              }
            }) : [];
          } else {
            this.services = [];
          }
        });
    },
    async getSalesConfiguration() {
      await ApiService.get(`sales/sales_configuration`).then((response) => {
        if (response.data.data.committed_type == 0){
          this.data.is_committed = false;
          this.is_committed_sales_disabled = true;
        }else if (response.data.data.committed_type == 1){
          this.data.is_committed = true;
          this.is_committed_sales_disabled = true;
        }else {
          this.data.is_committed = false;
          this.is_committed_sales_disabled = false;
        }
      });
    },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{
      title: this.$t("MENU.Items"),
      route: '/items/items'
    }, {title: (this.idEdit ? this.$t('update') : this.$t('create'))}]);
    let _promise = this.getSalesConfiguration();

    this.addBarcodeToRepeater();
    this.getCategories();
    this.getItems();
    this.getBrands();
    this.getTaxes();
    this.getUnits();
    this.getLaundryServices();
    this.getScaleTypesList();
    if (this.idEdit) {
      Promise.all([_promise]).then(()=>{
        this.getData(this.idEdit);
        this.getStatisticsItem();
      });
      // let promise = this.getDefaultTax();
      // Promise.all([promise]).then(()=>{
      //     if (this.items_list.length <= 0) {
      //         this.addItemRowToList();
      //     }
      // });
    } else {
      if (this.data_taxes.length <= 0) {
        this.addToRepeater();
      }
      this.defaultDataForUser();
      this.getPlanMessage();

      this.getCode();

    }

  },
};
</script>
